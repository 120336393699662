<template>
    <div class="project">
        <div class="intro grid">
            <h1>{{$t('hbn.title')}}</h1>
            <div class="cell">
                <p>{{$t('hbn.intro')}}</p>
            </div>
            <div class="cell">
                <dl>
                    <dt>{{$t('project.client')}}</dt>
                    <dd>{{$t('hbn.client')}}</dd>
                    <dt>{{$t('project.role')}}</dt>
                    <dd>{{$t('hbn.role')}}</dd>
                    <dt>{{$t('project.timeframe')}}</dt>
                    <dd>2019 - 2020</dd>
                    <dt>{{$t('project.website')}}</dt>
                    <dd><a href="https://www.hbnlawtax.com" class="button" target="_blank">www.hbnlawtax.com</a></dd>
                </dl>
            </div>
        </div>
        <div class="case">
            <div class="layout">
                <div class="layout__item layout__item--12">
                    <img src="@/assets/images/cases/hbn/site-1.jpg" alt="Website HBN Law & Tax">
                    <img src="@/assets/images/cases/hbn/site-2.jpg" alt="Website HBN Law & Tax">
                    <img src="@/assets/images/cases/hbn/site-3.jpg" alt="Website HBN Law & Tax">
                    <img src="@/assets/images/cases/hbn/site-4.jpg" alt="Website HBN Law & Tax">
                    <img src="@/assets/images/cases/hbn/site-5.jpg" alt="Website HBN Law & Tax">
                    <img src="@/assets/images/cases/hbn/site-6.jpg" alt="Website HBN Law & Tax">
                    <img src="@/assets/images/cases/hbn/site-7.jpg" alt="Website HBN Law & Tax">
                    <!-- <p class="caption">{{$t('hbn.website')}}</p> -->
                </div>        
            </div>
        </div>
        <OtherCases prev-route="/projects/cbr-rijdata" prev-title="CbrRijdata" next-route="/projects/fast-fluid" next-title="fastFluid" />
        </div>
</template>
<script>
import OtherCases from '@/components/OtherCases.vue'

export default {
    components: {
        OtherCases
    }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/pages/_Project.scss';
</style>